import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form', 'data']
  static values = { origin: String }
 
  connect() {
    this.setupListener()
  }

  setupListener() {
    window.addEventListener('message', event => {
      if (event.origin === this.originValue) {
        console.log('Message received from iframe:', event.data)
        this.dataTarget.value = JSON.stringify(event.data)
        this.formTarget.requestSubmit()
      }
    })
  }
}