import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    scrollTarget: String
  }

  connect() {
    document.addEventListener('turbo:frame-render', this.scrollToFrame.bind(this))
  }

  scrollToFrame(event) {
    if (event.target && event.target == this.element) {
      let target
      if (this.scrollTargetValue.length > 0) {
        target = document.querySelector(this.scrollTargetValue)
      }
      if (target) {
        target.scrollIntoView({ behavior: "smooth" })
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" })
      }
    }
  }
}