import { BaseController } from 'stimulus-library';
import { get } from 'lodash-es'

/*
 * to toggle on the value of an input, mark the input with the switch action, and the togglee with a `data-switch` attribute
 * to match input value when you want the togglee to be shown.
 *
 * Example:
 * ```
 *       -# shows the cost input only when the 'external' radio button is selected
 *       = f.input :completed_by, as: :radio_buttons,
 *        collection: [['Internally, 'internal'], ['Outsourced', 'external']],
 *        wrapper_html: {'data-action': 'change->toggler#switch'}
 *
 *       .hide{'data-toggler-target': 'togglee', 'data-switch': 'external'}= f.input :cost,
 * ```
 */
export default class Toggler extends BaseController {
  static targets = ['togglee']

  connect() {
    this.toggleeTargets.forEach((toggle) => {
      this.display_default_state(toggle);
    });
  }

  hide(e) {
    if (!e.target.checked && !e.target.selected) { return; }

    this.toggleeTargets.forEach((item) => {
      this.hide_item(item)
    });
  }

  show(e) {
    if (!e.target.checked && !e.target.selected) { return; }

    this.toggleeTargets.forEach((item) => {
      this.show_item(item)
    });
  }

  toggle() {
    this.toggleeTargets.forEach((item) => {
      this.toggle_item(item)
    });
  }

  switch(e) {
    this.toggleeTargets.forEach((item) => {
      if (item.dataset.switch == e.target.value) {
        this.show_item(item)
      } else {
        this.hide_item(item)
      }
    })
  }

  toggle_class() {
    return this.data.get('class') || 'hide'
  }

  toggle_item(item) {
    item.classList.toggle(this.toggle_class());
    if ("togglerFocus" in item.dataset && !item.classList.contains(this.toggle_class())) {
      let input = item.querySelector('input[type="text"], textarea')
      if (input) {
        input.focus()
        const temp_val = input.value
        input.value = ''
        input.value = temp_val
        input.scrollTop = 99999
      }
    }
  }

  show_item(item) {
    item.classList.remove(this.toggle_class());
  }

  hide_item(item) {
    item.classList.add(this.toggle_class());
  }

  // to optionally set a default show/hide state on a togglee, add the attribute 'data-togglee-default-state'='hide' as needed
  display_default_state(togglee) {
    const defaultState = get(togglee, 'dataset.toggleeDefaultState', null)
    if (!defaultState) { return; }

    switch (defaultState) {
      case 'show':
        this.show_item(togglee);
        break;
      case 'hide':
        this.hide_item(togglee);
        break;
      default:
        throw new Error(`invalid data-toggleeDefaultState: ${defaultState}`);
    };
  }
}
